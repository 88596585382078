import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { Parallax } from "react-parallax"
import HomepageImage from "../../static/img/parallax-homepage.jpg"
import SalvexHomeHouston from "../../static/img/salvex-home-houston.jpg"
import CaseStudy1 from "../../static/img/case-study-1.jpg"
import CaseStudy2 from "../../static/img/case-study-2.jpg"
import CaseStudy3 from "../../static/img/case-study-3.jpg"
import IconCharities from "../../static/img/icon-charities.png"
import IconCircularEconomy from "../../static/img/icon-circular-economy.png"
import IconSolarSolutions from "../../static/img/icon-solar-solutions.png"
import JoinOurTeam from "../../static/img/join-our-team.jpg"

const IndexPage = () => {
  return (
    <Layout>
      <Parallax bgImage={HomepageImage} className="text-center parallax-home">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <h2 className="text-white wow fadeInDown">
                Sustainability Solutions
              </h2>
              <h3 className="text-white wow fadeInLeft">
                for Major Corporations Globally Through Surplus Inventory Asset
                Recovery Sales
              </h3>
              <p className="mt-4 wow fadeInUp">
                <a
                  href="/sustainability-solutions"
                  className="btn btn-primary p-3 rounded-pill pl-5 pr-5"
                >
                  LEARN MORE
                </a>
              </p>
            </div>
          </div>
        </div>
      </Parallax>

      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-6 pt-4">
            <p className="h1 text-secondary font-weight-normal mt-4">
              Salvex has designed efficient and comprehensive sustainability
              solutions for major corporations globally.
            </p>
            <p className="mt-2">
              We manage the sale of all surplus and end-of-life assets while
              furthering their sustainability and environmental initiatives.
            </p>
            <p>
              Our online tools and comprehensive global database has given asset
              managers, procurement departments and CFO’s the ability to
              maximize the recovery of unneeded assets.{" "}
            </p>
          </div>
          <div className="col-md-12 col-lg-6 pt-4 pb-4">
            <img
              src={SalvexHomeHouston}
              alt="Houston Headquarters"
              className="mt-4 mb-4 img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="container-fluid bg-alt pb-4">
        <div class="container mb-4">
          <div className="row">
            <div className="col pt-4">
              <h2 className="text-primary mt-4 text-center">
                Commercial Repurposing Case Studies
              </h2>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-12 col-md-4">
              <div className="card">
                <img
                  src={CaseStudy1}
                  className="card-img-top"
                  alt="Waukesha Natural Gas Engine"
                />
                <div className="card-body">
                  <h5 className="card-title text-secondary">
                    Waukesha Natural Gas Engine
                  </h5>
                  <p className="card-text">
                    Repurposed the machine, saving the purchasing company
                    thousands while avoiding an increase in CO2 emissions due to
                    the production and transportation of a new machine.
                  </p>
                  <p className="text-center mt-4">
                    <a
                      href="/case-studies"
                      className="btn btn-primary btn-sm rounded-pill pl-4 pr-4"
                    >
                      Read More
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className="card">
                <img
                  src={CaseStudy2}
                  className="card-img-top"
                  alt="19,000 Solar Panels in Honduras Insurance Claim"
                />
                <div className="card-body">
                  <h5 className="card-title">
                    19,000 Solar Panels in Honduras Insurance Claim
                  </h5>
                  <p className="card-text">
                    Salvex recovers 19,000 solar panels from an insurance claim
                    in Honduras and re-purposes them for solar parks in the USA.
                  </p>
                  <p className="text-center mt-4">
                    <a
                      href="/case-studies"
                      className="btn btn-primary btn-sm rounded-pill pl-4 pr-4"
                    >
                      Read More
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-4">
              <div className="card">
                <img
                  src={CaseStudy3}
                  className="card-img-top"
                  alt="100,000 Feet of Structural Tubing Sustainably Re-purposed"
                />
                <div className="card-body">
                  <h5 className="card-title">
                    100,000 Feet of Structural Tubing Sustainably Re-purposed
                  </h5>
                  <p className="card-text">
                    Salvex sells surplus, structural pipe for a major oil and
                    gas company and the purchasing company re-purposes the pipe
                    in creative fencing and structural applications.
                  </p>
                  <p className="text-center mt-4">
                    <a
                      href="/case-studies"
                      className="btn btn-primary btn-sm rounded-pill pl-4 pr-4"
                    >
                      Read More
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid pb-4">
        <div class="container mb-4">
          <div className="row">
            <div className="col pt-4">
              <h2 className="text-primary mt-4 text-center">
                SUSTAINABILITY INDUSTRY SOLUTIONS
              </h2>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-4 col-md-3 col-lg-3">
              <p className="text-center mt-4 mb-4">
                <a href="/industries" class="btn btn-industry">
                  <span class="icon-agriculture" style={{ fontSize: "50px" }} />
                </a>
                <br />
                <a href="#" className="btn btn-link mt-2">
                  Agriculture
                </a>
              </p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-3">
              <p className="text-center mt-4 mb-4">
                <a href="/industries" class="btn btn-industry">
                  <span class="icon-corporate" style={{ fontSize: "50px" }} />
                </a>
                <br />
                <a href="#" className="btn btn-link mt-2">
                  Corporate Asset Recovery
                </a>
              </p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-3">
              <p className="text-center mt-4 mb-4">
                <a href="/industries" class="btn btn-industry">
                  <span class="icon-oilgas" style={{ fontSize: "50px" }} />
                </a>
                <br />
                <a href="#" className="btn btn-link mt-2">
                  Oil &amp; Gas and Utilities
                </a>
              </p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-3">
              <p className="text-center mt-4 mb-4">
                <a href="/industries" class="btn btn-industry">
                  <span class="icon-solar" style={{ fontSize: "50px" }} />
                </a>
                <br />
                <a href="#" className="btn btn-link mt-2">
                  Solar and Wind
                </a>
              </p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-3">
              <p className="text-center mt-4 mb-4">
                <a href="/industries" class="btn btn-industry">
                  <span class="icon-insurance" style={{ fontSize: "50px" }} />
                </a>
                <br />
                <a href="#" className="btn btn-link mt-2">
                  Insurance
                </a>
              </p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-3">
              <p className="text-center mt-4 mb-4">
                <a href="/industries" class="btn btn-industry">
                  <span class="icon-government" style={{ fontSize: "50px" }} />
                </a>
                <br />
                <a href="#" className="btn btn-link mt-2">
                  Government
                </a>
              </p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-3">
              <p className="text-center mt-4 mb-4">
                <a href="/industries" class="btn btn-industry">
                  <span
                    class="icon-heavy-equipment"
                    style={{ fontSize: "50px" }}
                  />
                </a>
                <br />
                <a href="#" className="btn btn-link mt-2">
                  Heavy Equipment
                </a>
              </p>
            </div>
            <div className="col-sm-4 col-md-3 col-lg-3">
              <p className="text-center mt-4 mb-4">
                <a href="/industries" class="btn btn-industry">
                  <span
                    class="icon-transportation"
                    style={{ fontSize: "50px" }}
                  />
                </a>
                <br />
                <a href="#" className="btn btn-link mt-2">
                  Transportation
                </a>
              </p>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12 text-center">
              <a
                href="/industries"
                className="btn btn-outline-primary btn-sm rounded-pill pl-4 pr-4 pt-2 pb-2 h3"
              >
                LEARN MORE
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-alt pb-4 mb-4">
        <div className="container">
          <div className="row">
            <div className="col pt-4">
              <h2 className="text-primary mt-4 text-center">Global Impact</h2>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-12 col-md-4">
              <p className="text-center mt-4 mb-4">
                <img src={IconSolarSolutions} alt="Recycled Solar" />
              </p>
              <h3 className="card-title text-secondary text-center h2 font-weight-normal">
                Recycled Solar
              </h3>
              <p className="card-text text-center">
                Salvex creates sustainability solutions for major corporations
                globally through surplus inventory asset recovery sales,
                develops comprehensive recycling solutions, and introduces
                economical solar solutions.
              </p>
              <p className="text-center mt-4">
                <a
                  href="/impact"
                  className="btn btn-outline-secondary btn-sm rounded-pill pl-4 pr-4 mt-2 mb-2"
                >
                  LEARN MORE
                </a>
              </p>
            </div>
            <div className="col-sm-12 col-md-4">
              <p className="text-center mt-4 mb-4">
                <img src={IconCircularEconomy} alt="Circular Economy" />
              </p>
              <h3 className="card-title text-secondary text-center h2 font-weight-normal">
                Circular Economy
              </h3>
              <p className="card-text text-center">
                Salvex contributes to the circular economy through its
                innovative platform which helps major corporations, insurance
                companies, manufacturers and government agencies recycle surplus
                and end-of-life assets.
              </p>
              <p className="text-center mt-4">
                <a
                  href="/impact"
                  className="btn btn-outline-secondary btn-sm rounded-pill pl-4 pr-4 mt-2 mb-2"
                >
                  LEARN MORE
                </a>
              </p>
            </div>
            <div className="col-sm-12 col-md-4">
              <p className="text-center mt-4 mb-4">
                <img src={IconCharities} alt="Repurposing Charities" />
              </p>
              <h3 className="card-title text-secondary text-center h2 font-weight-normal">
                Repurposing Charities
              </h3>
              <p className="card-text text-center">
                Salvex supports a wide array of charities through cash
                distributions, earned through the sale of donated assets which
                companies are looking to get off of their balance sheets to
                relieve certain tax burdens.{" "}
              </p>
              <p className="text-center mt-4">
                <a
                  href="/impact"
                  className="btn btn-outline-secondary btn-sm rounded-pill pl-4 pr-4 mt-2 mb-2"
                >
                  LEARN MORE
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Parallax bgImage={JoinOurTeam} className="text-center">
        <div className="container-fluid pt-4 pb-4">
          <div className="row pt-4 pb-4">
            <div className="col" />
            <div className="col-6 pt-4 pb-4">
              <h2 className="text-white font-weight-normal wow fadeInDown mt-4">
                Join Our Team
              </h2>
              <p className="text-white wow fadeInLeft mt-4 mb-4">
                Salvex is a global network of professionals devoted to helping
                our customers maximize profits through sustainability and
                asset-recovery programs.
              </p>
              <p className="mb-4 mt-4 wow fadeInUp">
                <a
                  href="https://jobs.salvex.com"
                  target="_blank"
                  className="btn btn-primary p-3 rounded-pill pl-5 pr-5"
                >
                  LEARN MORE
                </a>
              </p>
            </div>
            <div className="col" />
          </div>
        </div>
      </Parallax>
    </Layout>
  )
}
export default IndexPage
